<template>
  <div>
    <el-form
      ref="nodeForm"
      :rules="rules"
      label-position="top"
      label-width="100px"
      :model="nodeToBind"
      :hide-required-asterisk="false"
      @keydown.native.enter.exact.prevent.stop
    >
      <el-row type="flex">
        <el-col :span="24">
          <el-form-item prop="node_name" :label="__('Name')">
            <el-input v-model="nodeToBind.node_name"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-scrollbar :native="false">
        <div style="max-height: 59vh; padding-right: 5px;padding-bottom: 10px">
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item
                class="is-required"
                prop="prompt_node.data.prompt_text"
                :label="__('Prompt')"
                style="padding-right: 15px"
              >
                <input-variable-popper
                  :value="promptText"
                  is-content-editable
                  @input="updatePromptText($event)"
                  :is-text-area="false"
                  include-prompts
                  include-audio-variables
                  :include-secure-variables="false"
                  include-payments
                  :ats="getOptionsInitiators"
                  popper-class="prompt-node-popper"
                  class="promptEditor"
                  :popper-offset="-400"
                />
                <audio-player
                  class="audio-player"
                  :disabled="!promptText"
                  @get-preview="
                    generateAudio(
                      'promptText',
                      'generatingAudioForPrompt',
                      'promptAudioFile',
                      'promptAtAudioFileCreation'
                    )
                  "
                  :show-reload="promptContentChanged"
                  :generating-audio="generatingAudio"
                  :file="promptAudioFile"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24" style="height: 35px">
              <el-form-item
                prop="prompt_node.data.pause_before"
                :label="__('Synchronous flow')"
                style="display: inline-flex;"
              >
                <el-checkbox
                  style="padding-left: 15px; margin-bottom: 7px"
                  v-model="nodeToBind.prompt_node.data.pause_before"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24" style="width: 220px;">
              <div style="word-break: break-word; color: gray">
                Call flow will continue running only after prompt has finished
                playing.
              </div>
            </el-col>
          </el-row>
        </div>
      </el-scrollbar>
    </el-form>
  </div>
</template>

<script>
import BaseNode from "@/views/build/callflow/components/node-type-forms/BaseNode";
import GenerateAudio from "@/views/build/callflow/components/GenerateAudio";
import { NODE_TYPES } from "@/constants/nodes";
import { mapState } from "vuex";
import _ from "lodash";
import InputVariablePopper from "../components/InputVariablePopper";
import AudioPlayer from "@/components/AudioPlayer";

export default {
  components: {
    InputVariablePopper,
    AudioPlayer
  },
  mixins: [BaseNode, GenerateAudio],
  data() {
    let validatePrompt = (rule, value, cb) => {
      if (!value) {
        cb(__("Prompt text cannot be empty"));
      } else if (!value.trim()) {
        cb(__("Prompt text cannot be empty spaces"));
      } else {
        cb();
      }
    };
    return {
      rules: {
        prompt_node: {
          data: {
            prompt_text: {
              validator: validatePrompt,
              trigger: "blur"
            }
          }
        }
      }
      // promptAudioFile: "",
      // generatingAudio: false,
      // promptAtAudioFileCreation: ""
    };
  },
  computed: {
    ...mapState("prompts", {
      prompts: state => state.prompts
    }),
    promptText() {
      return this.nodeToBind.prompt_node.data.prompt_text;
    },
    getOptionsInitiators() {
      return ["{{", "[["];
    },
    promptContentChanged() {
      return this.promptText !== this.promptAtAudioFileCreation;
    }
  },
  created() {
    if (!this.nodeToBind.node_id || _.isEmpty(this.nodeToBind.prompt_node)) {
      this.$set(this.nodeToBind, "prompt_node", {});
      this.$set(this.nodeToBind.prompt_node, "data", {});
      this.$set(this.nodeToBind.prompt_node.data, "prompt_text", "");
      this.$set(this.nodeToBind.prompt_node.data, "pause_before", false);

      this.$set(this.nodeToBind, "node_type", NODE_TYPES.PROMPT.NODE_TYPE);
    }

    // this.changePercentage();
  },
  methods: {
    // changePercentage() {
    //   let self = this;
    //   setInterval(function() {
    //     self.percentage = self.increasePercentage(self.percentage);
    //     console.log(self.percentage);
    //   }, 1000);
    // },

    cleanUpNodeToPrepareForSubmit() {
      // do necessary operations on a cloned version of nodeToBind obj
      // that return the nodeToBind object
      // in exactly the same way as an node object of this
      // type is returned from the backend

      // as I do not require any cleanup to do here in this particular case,
      // I am just sending back a cloned version of nodeToBind obj
      return _.cloneDeep(this.nodeToBind);
    },
    cleanUpNode() {
      // the manipulation to clean up the node
      // is moved to the above method. So we can
      // reassign the nodeToBind object as the object
      // that is returned from the cleanUpNodeToPrepareForSubmit method
      this.nodeToBind = this.cleanUpNodeToPrepareForSubmit();
      this.createOrEditNode();
    },
    updatePromptText(value) {
      this.$set(this.nodeToBind.prompt_node.data, "prompt_text", value);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@/styles/node_common.scss";
.audio-player {
  min-width: 702px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.promptEditor {
  ::v-deep .editableContent {
    height: 200px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border: 1px solid #a0a8b5;
    outline: none;
    padding: 10px;
    overflow: auto;
    &:focus {
      border: 1px solid black;
    }
  }
}
</style>
